import { WidgetId } from '@wix/members-area-app-definitions';

import type {
  Section,
  MembersAreaPublicAPI as IMembersAreaPublicAPI,
} from '../types/services';
import { SANTA_MEMBERS_APP_DEF_ID } from '../constants/widget';

export class MembersAreaPublicAPI implements IMembersAreaPublicAPI {
  constructor(
    private readonly getPublicAPI: <T>(
      appDefId: string,
    ) => Promise<T | undefined>,
  ) {}

  async getSectionUrl(section: Section) {
    const membersApi = await this.getMembersAreaPublicAPI();
    return membersApi?.getSectionUrl(section) ?? '';
  }

  async getRoutes() {
    const membersApi = await this.getMembersAreaPublicAPI();
    return membersApi?.getRoutes();
  }

  async getSettingsRoutes() {
    const membersApi = await this.getMembersAreaPublicAPI();
    return membersApi?.getSettingsRoutes() ?? [];
  }

  async navigateToSection(widgetId: WidgetId) {
    const membersApi = await this.getMembersAreaPublicAPI();
    return membersApi?.navigateToSection(widgetId);
  }

  private getMembersAreaPublicAPI() {
    return this.getPublicAPI<IMembersAreaPublicAPI>(SANTA_MEMBERS_APP_DEF_ID);
  }
}
