import type {
  FFListState,
  FollowingFollowersListItem,
} from '../../types/followers';

import model from './model';

export default model.createController(({ $bind, $widget, initState }) => {
  return {
    pageReady: async () => {
      const initialProps: any = $widget.props;
      const initialMembers: FollowingFollowersListItem[] = initialProps.members;
      const { state } = initState<FFListState>({
        members: initialMembers,
        lastMemberShown: null,
      });

      $widget.onPropsChanged((_, { members }) => {
        state.members = members as unknown as FollowingFollowersListItem[];
      });

      $bind('#repeater', {
        data: () => state.members,
        item: (itemData, $bindItem, index) => {
          const listItem = itemData as FollowingFollowersListItem;

          $bindItem('#row', {
            onViewportEnter: () => {
              const isLastMemberShown = state.members.length - 1 === index;

              if (isLastMemberShown && listItem._id !== state.lastMemberShown) {
                state.lastMemberShown = listItem._id;
                $widget.fireEvent('lastMemberShown', {});
              }
            },
          });

          $bindItem('#title', {
            text: () => listItem.nickname,
          });

          $bindItem('#ctaMsb', {
            currentState: () => {
              switch (listItem.ctaAction) {
                case 'follow':
                  return 'follow';
                case 'unfollow':
                  return 'following';
                case 'none':
                default:
                  return 'empty';
              }
            },
          });

          $bindItem('#followButton', {
            onClick: () => $widget.fireEvent('memberFollowed', listItem._id),
            disabled: () => false,
          });

          $bindItem('#followingButton', {
            onClick: () => $widget.fireEvent('memberUnfollowed', listItem._id),
            disabled: () => false,
          });
        },
      });
    },
    exports: {},
  };
});
