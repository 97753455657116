import { runInAction } from 'mobx';

import type {
  FollowingFollowersListItem,
  FFModalState,
  CTAAction,
  InitFFModalState,
} from '../../../types/followers';

import { CTA_Action } from '../../../constants/followers';

const getMemberIdListItemIndexInList = (
  memberId: string,
  listItems: FollowingFollowersListItem[],
) => {
  const index = listItems.findIndex(({ _id }) => _id === memberId);
  return index;
};

const changeMemberCTAAction = (
  state: FFModalState,
  memberId: string,
  ctaAction: CTAAction,
) => {
  const indexInFollowersList = getMemberIdListItemIndexInList(
    memberId,
    state.followers.members,
  );

  if (indexInFollowersList !== -1) {
    state.followers.members[indexInFollowersList].ctaAction = ctaAction;
  }

  const indexInFollowingList = getMemberIdListItemIndexInList(
    memberId,
    state.following.members,
  );

  if (indexInFollowingList !== -1) {
    state.following.members[indexInFollowingList].ctaAction = ctaAction;
  }
};

export const createState: InitFFModalState = (
  initState,
  { followersReadService, followersWriteService },
) => {
  const fetchFollowingFollowers = async (memberId: string) => {
    const response = await followersReadService.getFollowingFollowers(memberId);
    state.followers = response.followers;
    state.following = response.following;
  };

  const fetchFollowers = async (memberId: string) => {
    if (state.followers.pagingMetadata?.cursors?.next) {
      const cursor = state.followers.pagingMetadata?.cursors?.next;
      const response = await followersReadService.getFollowers(
        memberId,
        cursor,
      );

      runInAction(() => {
        state.followers.members = [
          ...state.followers.members,
          ...response.members,
        ];
        state.followers.pagingMetadata = {
          count: state.followers.pagingMetadata?.count,
          cursors: response.pagingMetadata?.cursors,
        };
      });
    }
  };

  const fetchFollowing = async (memberId: string) => {
    if (state.following.pagingMetadata?.cursors?.next) {
      const cursor = state.following.pagingMetadata?.cursors?.next;
      const response = await followersReadService.getFollowing(
        memberId,
        cursor,
      );

      runInAction(() => {
        state.following.members = [
          ...state.following.members,
          ...response.members,
        ];
        state.following.pagingMetadata = {
          count: state.following.pagingMetadata?.count,
          cursors: response.pagingMetadata?.cursors,
        };
      });
    }
  };

  const followMember = async (memberId: string) => {
    await followersWriteService.follow(memberId);
    runInAction(() => {
      changeMemberCTAAction(state, memberId, CTA_Action.Unfollow);
    });
  };

  const unfollowMember = async (memberId: string) => {
    await followersWriteService.unfollow(memberId);
    runInAction(() => {
      changeMemberCTAAction(state, memberId, CTA_Action.Follow);
    });
  };

  const { state } = initState<FFModalState>({
    followers: { members: [], pagingMetadata: {} },
    following: { members: [], pagingMetadata: {} },
    fetchFollowingFollowers,
    fetchFollowers,
    fetchFollowing,
    followMember,
    unfollowMember,
  });

  return state;
};
