import model from './model';
import { createContext } from './context';

export default model.createController(({ $bind, flowAPI, initState }) => {
  return {
    pageReady: async () => {
      const { environment } = flowAPI;
      const { isBlocksPreview, isPreview, isEditor } = environment;
      const isDemoMode = isBlocksPreview || isPreview || isEditor;

      // TODO: In demo mode we will want to show fake data.
      // It is needed for editing experience.
      if (isDemoMode) {
        return;
      }

      const { state, lightboxContext } = createContext({
        flowAPI,
        initState,
      });

      await state.fetchFollowingFollowers(lightboxContext.memberId);

      $bind('#title', {
        text: () => lightboxContext.title,
      });

      $bind('#closeButton', {
        onClick: () => {
          const wixCodeAPI = flowAPI.controllerConfig.wixCodeApi;
          const lightboxAPI = wixCodeAPI.window.lightbox as {
            close?: Function;
          };

          return lightboxAPI.close?.();
        },
      });

      $bind('#ffList1', {
        // @ts-expect-error
        members: () => state.following.members,
        // @ts-expect-error
        onMemberFollowed: ({ data: memberId }) => {
          return state.followMember(memberId);
        },
        // @ts-expect-error
        onMemberUnfollowed: ({ data: memberId }) => {
          return state.unfollowMember(memberId);
        },
        onLastMemberShown: () => {
          return state.fetchFollowing(lightboxContext.memberId);
        },
      });

      $bind('#ffList2', {
        // @ts-expect-error
        members: () => state.followers.members,
        // @ts-expect-error
        onMemberFollowed: ({ data: memberId }) => {
          return state.followMember(memberId);
        },
        // @ts-expect-error
        onMemberUnfollowed: ({ data: memberId }) => {
          return state.unfollowMember(memberId);
        },
        onLastMemberShown: () => {
          return state.fetchFollowers(lightboxContext.memberId);
        },
      });

      $bind('#followingMsb', {
        currentState: () => {
          return state.following.members.length > 0
            ? 'followingContent'
            : 'followingEmptyState';
        },
      });

      $bind('#followersMsb', {
        currentState: () => {
          return state.followers.members.length > 0
            ? 'followersContent'
            : 'followersEmptyState';
        },
      });
    },
    exports: {},
  };
});
